<template>
    <div class="mc-container">
      <div class="header">
        <h4>Nova Orientação</h4>
        <b-button
            class="wh-button no-print"
            variant="primary"
            outline
            @click="openNewOrientation()"
          >
            Nova orientação
          </b-button>
      </div>
      <b-row>
        <v-orientation-table
          :orientations="orientationPatient"
          @deletedOrientacao="deletedOrientacao"
        />
      </b-row>
        <v-orientation-modal 
            :orientation="orientation" 
            @orientacaoCreated="orientacaoCreated"
            @orientacaoUpdated="orientacaoUpdated"
        />
    </div>
  </template>
    
    <script>
    import OrientationModal from '@/components/Orientation/OrientationModal.vue'
    import OrientationTable from '@/components/Orientation/OrientationTable.vue'
    import { EventBus } from '@/utils/eventBus'
  
    export default {
      name: 'Orientation',
      components: {
        'v-orientation-modal': OrientationModal,
        'v-orientation-table': OrientationTable,
      },
      async mounted() {
        await this.getOrientation()
        EventBus.$on('editOrientation', data => {
        this.orientation = data
      })
      },
      data() {
        return {
          search: null,
          orientation: null,
          orientationPatient: [],
          orientationOptions: [],
          currentPage: null,
          perPage: 3,
          totalItems: null
        }
      },
      methods: {
        async getOrientation() {
          try {
            const response = await this.api.getPostOperativeGuidelines({})
            this.orientationPatient = response.data
            this.orientationOptions = response.data.map(orientation => orientation.name)
          } catch (error) {
            this.$toast.error(error.message)
          } 
        },
      getDefaultForm() {     
          return {
            name: null,
            template: null,
          }
        },
        openNewOrientation() {
        this.orientation = this.getDefaultForm()
        this.$bvModal.show('orientation-modal')
      },
  
      deleteModal(orientation) {
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">
            Você tem certeza que deseja remover o comentário personalizado 
            <span>${orientation.name}</span>?
            <p>Não será possível reverter essa ação.</p>
            </div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
         }).then(async res => {
          if (res.isConfirmed) {
            await this.deleteTemplate(orientation)
          }
        })
      },
  
      deletedOrientacao(Orientacao) {
        const index = this.orientationPatient.findIndex(n => Orientacao.id === n.id) 
        this.orientationPatient.splice(index, 1)
      },
      orientacaoCreated(Orientacao) {
        this.orientationPatient.push(Orientacao)
      },
      orientacaoUpdated(Orientacao) {
        const orientacaoFound = this.orientationPatient.find(n => Orientacao.id === n.id) 
        orientacaoFound.name = Orientacao.name
        orientacaoFound.template = Orientacao.template
      },
      
      },
    }
    </script>
    
    <style lang="scss" scoped>
    .mc-container {
      min-height: 100vh;
      padding: 40px 20px;
    
      .header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    
        h4 {
          font-weight: 700;
        }
      }
    
      .search {
        border-radius: 8px !important;
        margin-bottom: 20px;
      }
    }
    </style>
    