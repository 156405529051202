<template>
    <b-table-simple striped class="table-eyecare">
      <thead>
        <tr>
          <th>Nome</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="orientation, index in orientations" :key="index"
        >
          <td>{{ orientation.name }}</td>
          <td class="text-right">
            <div class="more-with-menu">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  variant="link"
                  @click="editOrientation(orientation)"
                >
                  Editar
                </b-button>
                <b-button
                  variant="link remove mb-0"
                  @click="onDeleteClick(orientation)"
                >
                  Remover
                </b-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </template>
  
  <script>
  import { EventBus } from '@/utils/eventBus'
  import MoreVertical from '@/assets/icons/more-vertical.svg'
  
  export default {
    name: 'OrientationTable',
    components: {
      MoreVertical,
    },
  
    props: {
      orientations: Array,
      modal: Boolean,
    },
  
    methods: {
      editOrientation(orientation) {
        EventBus.$emit('editOrientation', orientation)
        this.$bvModal.show('orientation-modal')
      },
      onDeleteClick(orientation) {
        this.$bvModal
          .msgBoxConfirm(
            `Você tem certeza que deseja excluir ${orientation.name}? Não será possível reverter essa ação.`,
            {
              title: 'Excluir Orientação',
              size: 'md',
              okTitle: 'Confirmar',
              cancelTitle: 'Cancelar',
              cancelVariant: 'danger',
              centered: true
            }
          )
          .then(confirm => {
            confirm && this.onDelete(orientation)
          })
      },
      async onDelete(orientation) {
        try {
          await this.api.deletePostOperativeGuidelines(orientation.id);
          this.$emit('deletedOrientacao', orientation)
          this.$toast.success('Orientação removido com sucesso!')
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
    },
  }
  </script>
  <style lang="scss" >
  </style>
  